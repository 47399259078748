import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
import versions from '../../../gen-src/versions.json';
export const pageTitle = "ScalaPB integration";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const CodeBlock = makeShortcode("CodeBlock");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "scalapb-integration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#scalapb-integration",
        "aria-label": "scalapb integration permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ScalaPB integration`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#running-a-grpc-service"
          }}>{`Running a gRPC service`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#calling-a-grpc-service"
          }}>{`Calling a gRPC service`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#supporting-scalapb-in-annotated-services"
          }}>{`Supporting ScalaPB in annotated services.`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#converting-an-http-request-to-a-scalapbs-generatedmesage"
            }}>{`Converting an HTTP request to a ScalaPB's GeneratedMesage`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#returning-a-scalapbs-generatedmesage"
            }}>{`Returning a ScalaPB's GeneratedMesage`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#injecting-an-executioncontext-to-an-annotated-service-method"
            }}>{`Injecting an ExecutionContext to an annotated service method`}</a></li>
        </ul>
      </li>
    </ul>
    <Tip mdxType="Tip">
      <p>{`Visit `}<a parentName="p" {...{
          "href": "https://github.com/line/armeria/tree/main/examples/grpc-scala"
        }}>{`here`}</a>{` to find a fully working example.`}</p>
    </Tip>
    <p><a parentName="p" {...{
        "href": "https://scalapb.github.io/"
      }}>{`ScalaPB`}</a>{` is a protocol buffer compiler (protoc) plugin for
`}<a parentName="p" {...{
        "href": "https://www.scala-lang.org/"
      }}>{`Scala`}</a>{`. It will generate Scala case classes, parsers and serializers for
your `}<a parentName="p" {...{
        "href": "https://developers.google.com/protocol-buffers"
      }}>{`protocol buffers`}</a>{`.
ScalaPB also supports a thin wrapper around `}<a parentName="p" {...{
        "href": "https://grpc.io/docs/languages/java/quickstart/"
      }}>{`grpc-java`}</a>{`,
and provides you with an interface that is based on Scala's standard library
`}<a parentName="p" {...{
        "href": "https://docs.scala-lang.org/overviews/core/futures.html"
      }}>{`Future`}</a>{`,
while streaming is based on the Observer pattern.
If you integrate ScalaPB with Armeria, you can leverage the followings:`}</p>
    <ul>
      <li parentName="ul">{`Using both `}<a parentName="li" {...{
          "href": "https://armeria.dev/docs/server-docservice"
        }}>{`gRPC server`}</a>{` and
`}<a parentName="li" {...{
          "href": "https://armeria.dev/docs/client-grpc"
        }}>{`gRPC client`}</a>{` features such as gRPC-over-HTTP/1 and
`}<a parentName="li" {...{
          "href": "gRPC-Web"
        }}>{`gRPC-Web`}</a>{` protocol powered by Armeria`}</li>
      <li parentName="ul">{`Browsing the list of available RPC operations and invoking a service operation via a web console`}</li>
      <li parentName="ul">{`Converting either a Protocol Buffers or JSON to and from an
`}<a parentName="li" {...{
          "href": "https://scalapb.github.io/docs/generated-code"
        }}>{`scalapb.GeneratedMessage`}</a>{` in annotated service`}</li>
    </ul>
    <p>{`First, you need the `}<inlineCode parentName="p">{`armeria-scalapb_2.12`}</inlineCode>{` or `}<inlineCode parentName="p">{`armeria-scalapb_2.13`}</inlineCode>{` dependency to use the above features:`}</p>
    <CodeBlock language="groovy" filename="build.sbt" mdxType="CodeBlock">{`
libraryDependencies += "com.linecorp.armeria" %% "armeria-scalapb" % "${versions['com.linecorp.armeria:armeria-bom']}"
`}</CodeBlock>
    <Tip mdxType="Tip"> 
      <p><inlineCode parentName="p">{`armeria-scalapb`}</inlineCode>{` is only available for Scala 2.12.x and 2.13.x.`}</p>
    </Tip> 
    <h2 {...{
      "id": "running-a-grpc-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#running-a-grpc-service",
        "aria-label": "running a grpc service permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Running a gRPC service`}</h2>
    <p>{`You need to register a ScalaPB gRPC stub to a `}<a parentName="p" {...{
        "href": "type://GrpcService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcService.html"
      }}>{`type://GrpcService`}</a>{` using a `}<a parentName="p" {...{
        "href": "type://GrpcServiceBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/grpc/GrpcServiceBuilder.html"
      }}>{`type://GrpcServiceBuilder`}</a>{`
and add it to the `}<a parentName="p" {...{
        "href": "type://ServerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html"
      }}>{`type://ServerBuilder`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scala"
      }}>{`import com.linecorp.armeria.common.scalapb.ScalaPbJsonMarshaller
import com.linecorp.armeria.server.Server
import com.linecorp.armeria.server.docs.DocService
import com.linecorp.armeria.server.grpc.GrpcService

// Creates GrpcService with your gRPC stub generated by ScalaPB.
val grpcService =
      GrpcService
        .builder()
        // Add your ScalaPB gRPC stub using \`bindService()\`
        .addService(YourServiceGrpc.bindService(
          new YourServiceImpl, ExecutionContext.global))
        // Register \`ScalaPbJsonMarshaller\` for supporting gRPC JSON format.
        .jsonMarshallerFactory(_ => ScalaPbJsonMarshaller())
        .enableUnframedRequests(true)
        .build()

// Creates Armeria Server for ScalaPB gRPC stub.
Server.builder()
      .http(httpPort)
      .https(httpsPort)
      .service(grpcService)
      // Add DocService for browsing the list of gRPC services and 
      // invoking a service operation from a web form.
      // See https://armeria.dev/docs/server-docservice for more information.
      .serviceUnder("/docs", new DocService())
      .build()
`}</code></pre>
    <p>{`Please see `}<a parentName="p" {...{
        "href": "https://armeria.dev/docs/server-grpc"
      }}>{`gRPC service`}</a>{` for more information.`}</p>
    <h2 {...{
      "id": "calling-a-grpc-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#calling-a-grpc-service",
        "aria-label": "calling a grpc service permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Calling a gRPC service`}</h2>
    <p>{`You can also call a gRPC service using a ScalaPB gRPC client.
`}<inlineCode parentName="p">{`ScalaPbJsonMarshaller`}</inlineCode>{` should be registered with `}<a parentName="p" {...{
        "href": "type://GrpcClientBuilder#jsonMarshallerFactory(Function):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/grpc/GrpcClientBuilder.html#jsonMarshallerFactory(java.util.function.Function)"
      }}>{`type://GrpcClientBuilder#jsonMarshallerFactory(Function)`}</a>{` to
support gRPC JSON serialization format.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scala"
      }}>{`import com.linecorp.armeria.client.grpc.GrpcClients
import com.linecorp.armeria.common.grpc.GrpcSerializationFormats
import com.linecorp.armeria.common.scalapb.ScalaPbJsonMarshaller
        
val client = 
  GrpcClients.builder("http://127.0.0.1:8080/")
             .serializationFormat(GrpcSerializationFormats.JSON)
             // Register 'ScalaPbJsonMarshaller' for enabling gRPC JSON serialization format
             .jsonMarshallerFactory(_ => ScalaPbJsonMarshaller())
             .build(classOf[HelloServiceBlockingStub])
    
val request = HelloRequest("Armerian World")
val reply = helloService.hello(request)
assert(reply.message == "Hello, Armerian World!")
`}</code></pre>
    <p>{`Please see `}<a parentName="p" {...{
        "href": "https://armeria.dev/docs/client-grpc"
      }}>{`gRPC client`}</a>{` for more information.`}</p>
    <h2 {...{
      "id": "supporting-scalapb-in-annotated-services",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#supporting-scalapb-in-annotated-services",
        "aria-label": "supporting scalapb in annotated services permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Supporting ScalaPB in annotated services.`}</h2>
    <h3 {...{
      "id": "converting-an-http-request-to-a-scalapbs-generatedmesage",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#converting-an-http-request-to-a-scalapbs-generatedmesage",
        "aria-label": "converting an http request to a scalapbs generatedmesage permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Converting an HTTP request to a ScalaPB's `}<inlineCode parentName="h3">{`GeneratedMesage`}</inlineCode></h3>
    <ul>
      <li parentName="ul">{`A Protocol Buffers is automatically converted to a `}<inlineCode parentName="li">{`GeneratedMessage`}</inlineCode>{` only when
the content type is one of followings:`}
        <ul parentName="li">
          <li parentName="ul"><inlineCode parentName="li">{`application/probuf`}</inlineCode>{` `}</li>
          <li parentName="ul"><inlineCode parentName="li">{`application/octet-stream`}</inlineCode></li>
          <li parentName="ul"><inlineCode parentName="li">{`application/x-protobuf`}</inlineCode></li>
          <li parentName="ul">{`no media type negotiated`}</li>
        </ul>
      </li>
      <li parentName="ul">{`A JSON is automatically converted to a `}<inlineCode parentName="li">{`GeneratedMessage`}</inlineCode>{` only when the content type
is either `}<inlineCode parentName="li">{`application/json`}</inlineCode>{` or ends with `}<inlineCode parentName="li">{`+json`}</inlineCode>{`.`}</li>
    </ul>
    <p>{`If you have the following proto file,`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-protobuf"
      }}>{`syntax = "proto3";
package com.example.testing;
option java_package = "com.example.testing";

message SimpleRequest {
  string payload = 1;
  int32 size = 2;
}

message SimpleResponse {
  string message = 1;
  int32 status = 2;
}
`}</code></pre>
    <p>{`the `}<inlineCode parentName="p">{`SimpleRequest`}</inlineCode>{` generated by ScalaPB could be used for a parameter of your service method.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scala"
      }}>{`import com.example.testing.SimpleRequest
import com.linecorp.armeria.server.annotation.{ConsumesJson, ConsumesProtobuf, Post}

class GreetingService {
  // If a content type is not set, Protoco Buffers' parser is used by default.
  @Post("/no-content-type")
  def noContentType(request: SimpleRequest): String = s"Hello, \${request.payload}!"

  // Convert the Protocol Buffers in an HTTP payload into the 'SimpleRequest'
  @Post("/protobuf")
  @ConsumesProtobuf
  def consumeProtobuf(request: SimpleRequest): String = s"Hello, \${request.payload}!"

  // Convert the JSON in an HTTP payload into a 'SimpleRequest'
  @Post("/json")
  @ConsumesJson
  def consumeJson(request: SimpleRequest): String = "Hello, Armeria!"
}
`}</code></pre>
    <h3 {...{
      "id": "returning-a-scalapbs-generatedmesage",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#returning-a-scalapbs-generatedmesage",
        "aria-label": "returning a scalapbs generatedmesage permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Returning a ScalaPB's `}<inlineCode parentName="h3">{`GeneratedMesage`}</inlineCode></h3>
    <p>{`A `}<inlineCode parentName="p">{`GeneratedMesage`}</inlineCode>{` can be converted to either Protocol Buffers or JSON.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scala"
      }}>{`import com.example.testing.SimpleResponse
import scala.concurrent.Future

class GreetingService {

  // Convert 'SimpleResponse' into Protocol Buffers wire format
  @Get("/protobuf")
  @ProducesProtobuf
  def produceProtobuf: SimpleResponse = SimpleResponse("Hello, Armeria!")

  // Convert 'SimpleResponse' into JSON format
  @Get("/json")
  @ProducesJson
  def produceJson: SimpleResponse = SimpleResponse("Hello, Armeria!")

  // Convert 'SimpleResponse' into Protocol Buffers wire format with Scala Future
  @Get("/protobuf+async")
  @ProducesProtobuf
  def produceProtobufFuture: Future[SimpleResponse] = 
    Future { SimpleResponse("Hello, Armeria!") }
}
`}</code></pre>
    <p>{`However, a sequence of `}<inlineCode parentName="p">{`GeneratedMesage`}</inlineCode>{`s can be only converted to JSON array because Protocol Buffers
wire format is `}<a parentName="p" {...{
        "href": "https://developers.google.com/protocol-buffers/docs/techniques#streaming"
      }}>{`not self-delimiting`}</a>{`.
The following collection types can be converted to JSON array:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`scala.List`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`scala.Vector`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`scala.Set`}</inlineCode></li>
      <li parentName="ul">{`Reactive Stream `}<a parentName="li" {...{
          "href": "https://www.reactive-streams.org/reactive-streams-1.0.2-javadoc/org/reactivestreams/Publisher.html"
        }}>{`Publisher`}</a></li>
      <li parentName="ul"><inlineCode parentName="li">{`java.util.List`}</inlineCode>{` `}</li>
      <li parentName="ul"><inlineCode parentName="li">{`java.util.Set`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`java.util.stream.Stream`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "injecting-an-executioncontext-to-an-annotated-service-method",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#injecting-an-executioncontext-to-an-annotated-service-method",
        "aria-label": "injecting an executioncontext to an annotated service method permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Injecting an `}<a parentName="h3" {...{
        "href": "https://www.scala-lang.org/api/current/scala/concurrent/ExecutionContext.html"
      }}>{`ExecutionContext`}</a>{` to an annotated service method`}</h3>
    <p>{`An `}<inlineCode parentName="p">{`ExecutionContext`}</inlineCode>{` could be automatically injected as a method parameter to execute an asynchronous service
on top of an Armeria's event loop or blocking task executor.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-scala"
      }}>{`import com.linecorp.armeria.server.ServiceRequestContext
import com.linecorp.armeria.server.annoation.Blocking
import scala.concurrent.Future

class MyAsyncService {
  @Post("/async")
  def asyncService(req: SimpleRequest)(implicit ec: ExecutionContext): Future[String] =
    Future {
      // This callback will be executed in an Armeria's event loop
      assert(ServiceRequestContext.current() != null)
      "Hello, Armeria!"
    }

  @Blocking
  @Post("/blocking-task")
  def blockingAsyncService(req: SimpleRequest)(implicit ec: ExecutionContext): Future[String] =
    Future {
      // This callback will be executed in an Armeria's blocking task executor
      assert(ServiceRequestContext.current() != null)
      // Perform a long running task
      "Hello, Armeria!"
    }
  }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      